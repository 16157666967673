import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { contactUsForm } from "../schemas/formSchemas";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ContactUsForm = () => {
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      floating_first_name: "",
      floating_last_name: "",
      floating_email: "",
      floating_phone: "",
      message: "",
      captcha: "",
      otherRole: "",
    },
    validationSchema: contactUsForm,
    onSubmit: async (values, actions) => {
      const getStartedDB = {
        first_Name: values.floating_first_name,
        last_Name: values.floating_last_name,
        email: values.floating_email,
        phonenumber: values.floating_phone,
        comments: values.message,
        user_type: values?.role,
        other_user_type: showOtherInput ? values?.otherRole : null,
      };

      setLoading(true); // Start loading

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_CONTACTUS_FORM}`,
          // "https://onlineappointment.onrender.com/api/newinquires/storedata",
          getStartedDB
        );

        setSubmitting("success");
        actions.resetForm();
      } catch (error) {
        setSubmitting("error");
      } finally {
        setLoading(false); // End loading
      }
    },
  });

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
    formik.setFieldValue("captcha", value);
  };

  useEffect(() => {
    if (submitting === "success") {
      toast.success("Form submitted successfully!", {
        position: "top-center",
        className: "text-white",
      });
    } else if (submitting === "error") {
      toast.error(
        "There was an error submitting the form. Please contact admin.",
        {
          position: "top-center",
          className: "text-red-500 bg-white",
        }
      );
    }
  }, [submitting]);

  return (
    <>
      <div className="lg:flex ">
        <div className="relative lg:pl-[8%] px-10 py-20 h-full">
          <h1 className="text-5xl text-blue-700 font-black pb-5">
            Connect with Us
          </h1>
          <form
            onSubmit={formik.handleSubmit}
            className="max-w-2xl lg:mx-0 mx-auto"
          >
            <div className="grid md:grid-cols-2 md:gap-6">
              {/* First Name Field */}
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.floating_first_name}
                  className={`${
                    formik.errors.floating_first_name &&
                    formik.touched.floating_first_name
                      ? "border border-red-600"
                      : ""
                  } block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                  placeholder=" "
                />
                {formik.errors.floating_first_name &&
                  formik.touched.floating_first_name && (
                    <p className="text-sm font-semibold text-red-500">
                      {formik.errors.floating_first_name}
                    </p>
                  )}
                <label
                  htmlFor="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  First name
                </label>
              </div>
              {/* Last Name Field */}
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_last_name"
                  id="floating_last_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.floating_last_name}
                  className={`${
                    formik.errors.floating_last_name &&
                    formik.touched.floating_last_name
                      ? "border border-red-600"
                      : ""
                  } block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                  placeholder=" "
                />
                {formik.errors.floating_last_name &&
                  formik.touched.floating_last_name && (
                    <p className="text-sm font-semibold text-red-500">
                      {formik.errors.floating_last_name}
                    </p>
                  )}
                <label
                  htmlFor="floating_last_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Last name
                </label>
              </div>
            </div>
            {/* Email Field */}
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="email"
                name="floating_email"
                id="floating_email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.floating_email}
                className={`${
                  formik.errors.floating_email && formik.touched.floating_email
                    ? "border border-red-600"
                    : ""
                } block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                placeholder=" "
              />
              {formik.errors.floating_email &&
                formik.touched.floating_email && (
                  <p className="text-sm font-semibold text-red-500">
                    {formik.errors.floating_email}
                  </p>
                )}
              <label
                htmlFor="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email address
              </label>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              {/* Phone Field */}
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="tel"
                  name="floating_phone"
                  id="floating_phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.floating_phone}
                  className={`${
                    formik.errors.floating_phone &&
                    formik.touched.floating_phone
                      ? "border border-red-600"
                      : ""
                  } block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                  placeholder=" "
                />
                {formik.errors.floating_phone &&
                  formik.touched.floating_phone && (
                    <p className="text-sm font-semibold text-red-500">
                      {formik.errors.floating_phone}
                    </p>
                  )}
                <label
                  htmlFor="floating_phone"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Phone number (123-456-7890)
                </label>
              </div>
            </div>
            {/* Message Field */}
            <div className="relative z-0 w-full mb-5 group">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-500 dark:text-white"
              >
                Question / Comments
              </label>
              <textarea
                id="message"
                rows="4"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.message}
                className={`${
                  formik.errors.message && formik.touched.message
                    ? "border border-red-600"
                    : ""
                } block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Leave a Question / Comments..."
              ></textarea>
              {formik.errors.message && formik.touched.message && (
                <p className="text-sm font-semibold text-red-500">
                  {formik.errors.message}
                </p>
              )}
            </div>
            {/* Radio Buttons */}
            <div className="relative z-0 w-full mb-5 group">
              <label className="block mb-2 text-sm font-medium text-gray-500 dark:text-white">
                Select your role
              </label>
              <div className="flex gap-4">
                <label className="flex items-center gap-2 text-sm text-gray-700 dark:text-white">
                  <input
                    type="radio"
                    name="role"
                    value="parent"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setShowOtherInput(false);
                    }}
                    checked={formik?.values?.role === "parent"}
                    className="accent-blue-500"
                  />
                  I am a Parent
                </label>
                <label className="flex items-center gap-2 text-sm text-gray-700 dark:text-white">
                  <input
                    type="radio"
                    name="role"
                    value="professional"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setShowOtherInput(false);
                    }}
                    checked={formik?.values?.role === "professional"}
                    className="accent-blue-500"
                  />
                  I am a Professional
                </label>
                <label className="flex items-center gap-2 text-sm text-gray-700 dark:text-white">
                  <input
                    type="radio"
                    name="role"
                    value="others"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setShowOtherInput(true);
                    }}
                    checked={formik?.values?.role === "others"}
                    className="accent-blue-500"
                  />
                  Others
                </label>
              </div>
            </div>

            {/* Other Input Field (Visible if 'Others' is selected) */}
            {showOtherInput && (
              <div className="relative z-0 w-full mb-5 group">
                <label
                  htmlFor="otherRole"
                  className="block mb-2 text-sm font-medium text-gray-500 dark:text-white"
                >
                  Please specify
                </label>
                <input
                  id="otherRole"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.otherRole}
                  className={`${
                    formik?.errors?.otherRole && formik?.touched?.otherRole
                      ? "border border-red-600"
                      : ""
                  } block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Enter your role..."
                />
                {formik?.errors?.otherRole && formik?.touched?.otherRole && (
                  <p className="text-sm font-semibold text-red-500">
                    {formik?.errors?.otherRole}
                  </p>
                )}
              </div>
            )}

            <div className="pb-3">
              <p className="text-sm font-semibold text-blue-600">
                Your privacy is important to us. All information shared will be
                kept confidential and used solely to respond to your inquiries
                and provide requested services.*
              </p>
            </div>
            {/* CAPTCHA */}
            <div className="py-5" id="captcha">
              <ReCAPTCHA
                sitekey="6LcfLFUoAAAAACno3hdClnckkDsl4ERrkfhX7Alr"
                onChange={handleRecaptcha}
              />
              {formik.errors.captcha && formik.touched.captcha ? (
                <p className="text-sm font-semibold text-red-500">
                  {formik.errors.captcha}
                </p>
              ) : (
                ""
              )}
            </div>
            {/* Submit Button */}
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-white font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:border-blue-600 hover:text-blue-600"
              disabled={loading} // Disable button when loading
            >
              {loading ? "Submitting..." : "Submit"}{" "}
              {/* Change button text based on loading */}
            </button>
            {loading && (
              <div className="absolute top-1/2 left-[30%] flex justify-center py-3">
                <div className="loader"></div>{" "}
                {/* Add your loading spinner CSS here */}
              </div>
            )}
          </form>
        </div>
        <div className="flex flex-col mx-auto w-[95%] lg:w-[40%] py-[5%] h-full">
          <div className="flex flex-col gap-14  justify-start rounded-3xl bg-blue-600 text-center py-[10%]  lg:py-[20%] h-[100%]">
            <h1 className="text-5xl lg:text-6xl text-white font-black">
              Let's connect
            </h1>
            <p className="text-white text-justify px-5 lg:px-12 md:px-12  align-center justify-center font-medium">
              Still unsure how ELiNA’s programs can support your child? We
              understand that every family’s needs are unique. Book a 55 minutes
              consultation with our experts to get personalized guidance on how
              our programs like SAIL and CoMPASS can help your child thrive. Let
              us help you take the next step in your child’s journey
            </p>

            <button
              onClick={() => navigate("/contact/getintouch")}
              className="w-fit ml-auto mr-auto items-center py-2 px-4 bg-white lg:text-3xl border-white text-blue-600 hover:border-white hover:text-white"
            >
              Book a consultation
            </button>
            {/* <div className="flex flex-col  text-sm gap-5 text-center px-10 lg:px-20 text-gray-100">
              <p>
                <span className="font-bold text-base lg:text-lg">
                  Location:{" "}
                </span>
                C1 - 301, Pelican Nest, Creek Street, OMR Chennai Tamil Nadu
                600097 India.
              </p>
              <p>
                <span className="font-bold text-base lg:text-lg">Phone: </span>
                9841050686 , +91 984 079 4434
              </p>
              <p>
                <span className="font-bold text-base lg:text-lg">
                  Office hours:{" "}
                </span>
                <br />
                Business Hours: Monday to Friday: 9 AM - 5 PM <br />
                Saturday and Sunday: Closed
              </p>
            </div> */}
            {/* <div className="flex justify-center gap-5">
              <a
                href="https://www.facebook.com/ELiNA.Edu?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path d="M16,2c-7.732,0-14,6.268-14,14,0,6.566,4.52,12.075,10.618,13.588v-9.31h-2.887v-4.278h2.887v-1.843c0-4.765,2.156-6.974,6.835-6.974,.887,0,2.417,.174,3.043,.348v3.878c-.33-.035-.904-.052-1.617-.052-2.296,0-3.183,.87-3.183,3.13v1.513h4.573l-.786,4.278h-3.787v9.619c6.932-.837,12.304-6.74,12.304-13.897,0-7.732-6.268-14-14-14Z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/ELiNAservices?igsh=dWVjY3lrZXp2N3Z5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path d="M10.202,2.098c-1.49,.07-2.507,.308-3.396,.657-.92,.359-1.7,.84-2.477,1.619-.776,.779-1.254,1.56-1.61,2.481-.345,.891-.578,1.909-.644,3.4-.066,1.49-.08,1.97-.073,5.771s.024,4.278,.096,5.772c.071,1.489,.308,2.506,.657,3.396,.359,.92,.84,1.7,1.619,2.477,.779,.776,1.559,1.253,2.483,1.61,.89,.344,1.909,.579,3.399,.644,1.49,.065,1.97,.08,5.771,.073,3.801-.007,4.279-.024,5.773-.095s2.505-.309,3.395-.657c.92-.36,1.701-.84,2.477-1.62s1.254-1.561,1.609-2.483c.345-.89,.579-1.909,.644-3.398,.065-1.494,.081-1.971,.073-5.773s-.024-4.278-.095-5.771-.308-2.507-.657-3.397c-.36-.92-.84-1.7-1.619-2.477s-1.561-1.254-2.483-1.609c-.891-.345-1.909-.58-3.399-.644s-1.97-.081-5.772-.074-4.278,.024-5.771,.096m.164,25.309c-1.365-.059-2.106-.286-2.6-.476-.654-.252-1.12-.557-1.612-1.044s-.795-.955-1.05-1.608c-.192-.494-.423-1.234-.487-2.599-.069-1.475-.084-1.918-.092-5.656s.006-4.18,.071-5.656c.058-1.364,.286-2.106,.476-2.6,.252-.655,.556-1.12,1.044-1.612s.955-.795,1.608-1.05c.493-.193,1.234-.422,2.598-.487,1.476-.07,1.919-.084,5.656-.092,3.737-.008,4.181,.006,5.658,.071,1.364,.059,2.106,.285,2.599,.476,.654,.252,1.12,.555,1.612,1.044s.795,.954,1.051,1.609c.193,.492,.422,1.232,.486,2.597,.07,1.476,.086,1.919,.093,5.656,.007,3.737-.006,4.181-.071,5.656-.06,1.365-.286,2.106-.476,2.601-.252,.654-.556,1.12-1.045,1.612s-.955,.795-1.608,1.05c-.493,.192-1.234,.422-2.597,.487-1.476,.069-1.919,.084-5.657,.092s-4.18-.007-5.656-.071M21.779,8.517c.002,.928,.755,1.679,1.683,1.677s1.679-.755,1.677-1.683c-.002-.928-.755-1.679-1.683-1.677,0,0,0,0,0,0-.928,.002-1.678,.755-1.677,1.683m-12.967,7.496c.008,3.97,3.232,7.182,7.202,7.174s7.183-3.232,7.176-7.202c-.008-3.97-3.233-7.183-7.203-7.175s-7.182,3.233-7.174,7.203m2.522-.005c-.005-2.577,2.08-4.671,4.658-4.676,2.577-.005,4.671,2.08,4.676,4.658,.005,2.577-2.08,4.671-4.658,4.676-2.577,.005-4.671-2.079-4.676-4.656h0"></path>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@ELiNAservices"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path d="M31.331,8.248c-.368-1.386-1.452-2.477-2.829-2.848-2.496-.673-12.502-.673-12.502-.673,0,0-10.007,0-12.502,.673-1.377,.37-2.461,1.462-2.829,2.848-.669,2.512-.669,7.752-.669,7.752,0,0,0,5.241,.669,7.752,.368,1.386,1.452,2.477,2.829,2.847,2.496,.673,12.502,.673,12.502,.673,0,0,10.007,0,12.502-.673,1.377-.37,2.461-1.462,2.829-2.847,.669-2.512,.669-7.752,.669-7.752,0,0,0-5.24-.669-7.752ZM12.727,20.758V11.242l8.364,4.758-8.364,4.758Z"></path>
                </svg>
              </a>
              <a
                href="https://x.com/ELiNA_service?t=XPIZjyRN0-GxJDrjAkKftw&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path d="M18.42,14.009L27.891,3h-2.244l-8.224,9.559L10.855,3H3.28l9.932,14.455L3.28,29h2.244l8.684-10.095,6.936,10.095h7.576l-10.301-14.991h0Zm-3.074,3.573l-1.006-1.439L6.333,4.69h3.447l6.462,9.243,1.006,1.439,8.4,12.015h-3.447l-6.854-9.804h0Z"></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/ELiNA-services/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path
                    d="M26.111,3H5.889c-1.595,0-2.889,1.293-2.889,2.889V26.111c0,1.595,1.293,2.889,2.889,2.889H26.111c1.595,0,2.889-1.293,2.889-2.889V5.889c0-1.595-1.293-2.889-2.889-2.889ZM10.861,25.389h-3.877V12.87h3.877v12.519Zm-1.957-14.158c-1.267,0-2.293-1.034-2.293-2.31s1.026-2.31,2.293-2.31,2.292,1.034,2.292,2.31-1.026,2.31-2.292,2.31Zm16.485,14.158h-3.858v-6.571c0-1.802-.685-2.809-2.111-2.809-1.551,0-2.362,1.048-2.362,2.809v6.571h-3.718V12.87h3.718v1.686s1.118-2.069,3.775-2.069,4.556,1.621,4.556,4.975v7.926Z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;
