// import React, { useEffect, useState, useRef } from "react";
// import bot from "../../images/bot.png";
// import send from "../../images/send1.png";
// import ELiNAservices from "../../images/elinaservice.png";
// import doubletick from "../../images/doubletick.png";
// import sessionStorage from "redux-persist/es/storage/session";
// const Chatbot = ({ showChatbot, setShowChatbot, random }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [inputMessage, setInputMessage] = useState("");
//   const [messages, setMessages] = useState([]);

//   const messagesEndRef = useRef(null);
//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   useEffect(
//     () => async () => {
//       const session = JSON.parse(
//         await sessionStorage.getItem("elina-chat-bot")
//       );
//       if (session && session?.length) {
//         setMessages(session);
//       }
//     },
//     []
//   );

//   useEffect(() => {
//     scrollToBottom();
//   }, [messages]);

//   // useEffect(
//   //   () => async () => {
//   //     const res = await fetch(
//   //       `https://elinaservices.com:7005/api/getSession?sessionId=${sessionStorage.getItem(
//   //         "sessionId"
//   //       )}`
//   //     );
//   //     const data = await res.json();

//   //     if (data?.results?.[0]?.session_id) {
//   //       const newData = data?.results?.map((elem) => {
//   //         return { [elem?.user_message]: elem?.chatbot_response };
//   //       });
//   //       setMessages(newData);
//   //     }
//   //   },
//   //   []
//   // );

//   const handleClose = async (random) => {
//     setShowChatbot(!showChatbot);
//     try {
//       const res = await fetch(
//         "https://elinaservices.com:7005/api/sessionUpdate",
//         {
//           method: "POST",
//           headers: {
//             "content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             sessionId: random,
//           }),
//         }
//       );
//       const data = await res.json();
//     } catch (e) {
//       console.log("flag is note updated.");
//     }
//   };

//   // const handleSubmit = async (event, random) => {
//   //   event.preventDefault();
//   //   if (!inputMessage.trim()) {
//   //     return;
//   //   }
//   //   setIsLoading(true);
//   //   try {
//   //     const res = await fetch("https://elinaservices.com:7005/api/dialogflow", {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //       body: JSON.stringify({ message: inputMessage }),
//   //     });

//   //     if (!res.ok) {
//   //       throw new Error("Failed to fetch response from the server.");
//   //     }

//   //     const data = await res.json();
//   //     const botResponse = data?.response || "No response";
//   //     const newMessage = { [inputMessage]: botResponse };
//   //     setMessages((prevMessages) => [...prevMessages, newMessage]);
//   //     await InsertChat(inputMessage, botResponse, random);
//   //   } catch (e) {
//   //     console.error("Error occurred:", e);
//   //     console.log("An error occurred while submitting the message.");
//   //   } finally {
//   //     setIsLoading(false);
//   //     setInputMessage("");
//   //   }
//   // };

//   const handleSubmit = async (event, random) => {
//     event.preventDefault();
//     if (!inputMessage.trim()) {
//       return;
//     }
//     setIsLoading(true);
//     try {
//       const res = await fetch("http://127.0.0.1:2025/fetch-info", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ query: inputMessage }),
//       });

//       const data = await res.json();
//       const botResponse = data?.response || "No response";

//       // const newMessage = { [inputMessage]: botResponse };
//       const newState = messages.slice(messages.length - 1);
//       console.log(newState);
//       setMessages(newState);
//       setMessages((prevMessages) => [
//         ...prevMessages,
//         { user: inputMessage, bot: botResponse },
//       ]);
//       sessionStorage.setItem(
//         "elina-chat-bot",
//         JSON.stringify([...messages, { user: inputMessage, bot: botResponse }])
//       );
//       // await InsertChat(inputMessage, botResponse, random);
//     } catch (e) {
//       console.error("Error occurred:", e);
//       console.log("An error occurred while submitting the message.");
//     } finally {
//       setIsLoading(false);
//       setInputMessage("");
//     }
//   };

//   const InsertChat = async (userMessage, botResponse, random) => {
//     try {
//       const res = await fetch(
//         "https://elinaservices.com:7005/api/insertNewChat",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             sessionId: random,
//             userMessage: userMessage,
//             botResponse: botResponse,
//           }),
//         }
//       );

//       if (!res.ok) {
//         throw new Error("Failed to insert chat message.");
//       }

//       const data = await res.json();
//       console.log("Chat inserted:", data);
//     } catch (e) {
//       console.error("Error storing chat messages:", e);
//       console.log("Failed to store the chat messages.");
//     }
//   };

//   return (
//     <>
//       <div className="bg-white w-96 rounded-lg justify-between flex flex-col">
//         <div className="bg-pink-500 text-white flex p-4 rounded-lg">
//           <div className="justify-center items-center flex">
//             <img className="w-12 h-12" src={ELiNAservices} alt="bot" />
//           </div>

//           <div className="flex flex-row px-2 w-full justify-between">
//             <div>
//               <div className="text-2xl">ELiNABot</div>
//               <div className="flex gap-2">
//                 <div className="items-center flex">
//                   <div className="w-2 h-2 rounded-full bg-green-400"></div>
//                 </div>
//                 <div>Online</div>
//               </div>
//             </div>
//             <div
//               className="h-full items-center justify-center flex"
//               onClick={() => {
//                 handleClose(random);
//               }}
//             >
//               <button className="p-3 text-xl text-white hover:text-white border-none bg-pink-500">
//                 X
//               </button>
//             </div>
//           </div>
//         </div>

//         <div className="overflow-y-scroll scroll-smooth h-80 md:h-96 py-2 px-4">
//           <div className="flex justify-start gap-2">
//             <div>
//               <img className="w-6 h-6" src={bot} alt="bot" />
//             </div>
//             <div>
//               <span className="bg-pink-500 text-white px-2 py-1 rounded-lg">
//                 How Can I Help you?
//               </span>
//             </div>
//           </div>
//           {messages.map((item, index) => (
//             <div key={index}>
//               <div className="flex justify-end w-full">
//                 <div className="flex flex-col gap-2">
//                   <div className="mt-2 max-w-60 w-fit h-fit bg-pink-200 rounded-lg p-2">
//                     <span className=" h-fit text-black  py-1 ">
//                       {item.user}
//                     </span>
//                   </div>
//                   <div className="flex justify-end">
//                     <img className="w-4 h-4" src={doubletick} alt="bot" />
//                   </div>
//                 </div>
//               </div>
//               <div className="flex justify-start w-full">
//                 <div className="flex flex-row gap-1">
//                   <div>
//                     <img className="w-6 h-6" src={bot} alt="bot" />
//                   </div>
//                   <div className="w-52 bg-pink-500 p-2 rounded-lg h-fit">
//                     <span className="text-white py-1 rounded-lg">
//                       {item.bot}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//           <div ref={messagesEndRef} />
//         </div>

//         <form
//           onSubmit={(e) => {
//             setMessages((prevMessages) => [
//               ...prevMessages,
//               { user: inputMessage, bot: "Loading ..." },
//             ]);
//             setInputMessage("");
//             handleSubmit(e, random);
//           }}
//         >
//           <div className="rounded-b-xl align-middle gap-3 flex flex-row justify-center mt-3 bg-pink-500 py-4">
//             <div className="w-3/4">
//               <input
//                 type="text"
//                 value={inputMessage}
//                 onChange={(e) => setInputMessage(e.target.value)}
//                 className="text-black rounded-xl w-full border-white border-2"
//                 placeholder="Enter the text"
//                 disabled={isLoading}
//               />
//             </div>
//             <div>
//               <button
//                 type="submit"
//                 className="bg-white border-2 border-white p-2 rounded-xl hover:bg-white active:border-pink-500"
//                 disabled={isLoading}
//               >
//                 <img src={send} alt="send" />
//               </button>
//             </div>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default Chatbot;

import React, { useEffect, useState, useRef } from "react";
import bot from "../../images/bot.png";
import send from "../../images/send1.png";
import ELiNAservices from "../../images/elinaservice.png";
import doubletick from "../../images/doubletick.png";
const Chatbot = ({ showChatbot, setShowChatbot, random }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(
    () => async () => {
      const res = await fetch(
        `https://elinaservices.com:7005/api/getSession?sessionId=${sessionStorage.getItem(
          "sessionId"
        )}`
      );
      const data = await res.json();

      if (data?.results?.[0]?.session_id) {
        const newData = data?.results?.map((elem) => {
          return { [elem?.user_message]: elem?.chatbot_response };
        });
        setMessages(newData);
      }
    },
    []
  );
  const handleClose = async (random) => {
    setShowChatbot(!showChatbot);
    try {
      const res = await fetch(
        "https://elinaservices.com:7005/api/sessionUpdate",
        {
          method: "POST",
          headers: {
            "content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId: random,
          }),
        }
      );
      const data = await res.json();
    } catch (e) {
      console.log("flag is note updated.");
    }
  };

  const handleSubmit = async (event, random) => {
    event.preventDefault();
    if (!inputMessage.trim()) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await fetch("https://elinaservices.com:7005/api/dialogflow", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: inputMessage }),
      });

      if (!res.ok) {
        throw new Error("Failed to fetch response from the server.");
      }

      const data = await res.json();
      const botResponse = data?.response || "No response";
      const newMessage = { [inputMessage]: botResponse };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      await InsertChat(inputMessage, botResponse, random);
    } catch (e) {
      console.error("Error occurred:", e);
    } finally {
      setIsLoading(false);
      setInputMessage("");
    }
  };

  const InsertChat = async (userMessage, botResponse, random) => {
    try {
      const res = await fetch(
        "https://elinaservices.com:7005/api/insertNewChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId: random,
            userMessage: userMessage,
            botResponse: botResponse,
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Failed to insert chat message.");
      }

      const data = await res.json();
    } catch (e) {
      console.error("Error storing chat messages:", e);
      console.log("Failed to store the chat messages.");
    }
  };

  return (
    <>
      <div className="bg-white w-96 rounded-lg justify-between flex flex-col">
        <div className="bg-pink-500 text-white flex p-4 rounded-lg">
          <div className="justify-center items-center flex">
            <img className="w-12 h-12" src={ELiNAservices} alt="bot" />
          </div>

          <div className="flex flex-row px-2 w-full justify-between">
            <div>
              <div className="text-2xl">ELiNABot</div>
              <div className="flex gap-2">
                <div className="items-center flex">
                  <div className="w-2 h-2 rounded-full bg-green-400"></div>
                </div>
                <div>Online</div>
              </div>
            </div>
            <div
              className="h-full items-center justify-center flex"
              onClick={() => {
                handleClose(random);
              }}
            >
              <button className="p-3 text-xl text-white hover:text-white border-none bg-pink-500">
                X
              </button>
            </div>
          </div>
        </div>

        <div className="overflow-y-scroll scroll-smooth h-80 md:h-96 py-2 px-4">
          <div className="flex justify-start gap-2">
            <div>
              <img className="w-6 h-6" src={bot} alt="bot" />
            </div>
            <div>
              <span className="bg-pink-500 text-white px-2 py-1 rounded-lg">
                How Can I Help you?
              </span>
            </div>
          </div>
          {messages.map((item, index) => (
            <div key={index}>
              <div className="flex justify-end w-full">
                <div className="flex flex-col gap-2">
                  <div className="mt-2 max-w-60 w-fit h-fit bg-pink-200 rounded-lg p-2">
                    <span className=" h-fit text-black  py-1 ">
                      {Object.keys(item)}
                    </span>
                  </div>
                  <div className="flex justify-end">
                    <img className="w-4 h-4" src={doubletick} alt="bot" />
                  </div>
                </div>
              </div>
              <div className="flex justify-start w-full">
                <div className="flex flex-row gap-1">
                  <div>
                    <img className="w-6 h-6" src={bot} alt="bot" />
                  </div>
                  <div className="w-52 bg-pink-500 p-2 rounded-lg h-fit">
                    <span className="text-white py-1 rounded-lg">
                      {Object.values(item)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <form
          onSubmit={(e) => {
            handleSubmit(e, random);
          }}
        >
          <div className="rounded-b-xl align-middle gap-3 flex flex-row justify-center mt-3 bg-pink-500 py-4">
            <div className="w-3/4">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                className="text-black rounded-xl w-full border-white border-2"
                placeholder="Enter the text"
                disabled={isLoading}
              />
            </div>
            <div>
              <button
                type="submit"
                className="bg-white border-2 border-white p-2 rounded-xl hover:bg-white active:border-pink-500"
                disabled={isLoading}
              >
                <img src={send} alt="send" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Chatbot;
